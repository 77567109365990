.main-leader {
  width: 70%;
  margin: 0 auto;
}

.main-leader img,
.other-leaders div img {
  border: 3px #333 solid;
}
.other-leaders div img {
  border: 3px #555 solid;
}

@media screen and (min-width: 769px) {
  .main-leader {
    width: 30%;
  }
}
