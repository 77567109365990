.pagination {
  &__container {
    border: 1px solid #ddd;
    border-radius: 2rem;
  }
  &__btn {
    color: #aaa;
    border-radius: 50%;
    transition: all ease-in-out 350ms;
    i {
      padding: 0.7rem;
    }
    &:hover:not(:disabled) {
      background-color: var(--primary-color);
      color: var(--white);
    }
    &:active:not(:disabled) {
      background-color: var(--primary-color);
      color: var(--white);
    }
    &:disabled {
      cursor: auto;
    }
  }

  select {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
}
