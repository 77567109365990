.sermon-cards-tray {
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.sermon-card {
  overflow: hidden;
}

.sermon-card__b_line {
  width: 25%;
  height: 2px;
  background-color: var(--primary-color);
}

.sermon-card-details {
  max-width: 90%;
}

.download-btn {
  color: #888;
  width: 50px;
  height: 50px;
  font-size: 1.5rem;
}
.download-btn:hover {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
}
