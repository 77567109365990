@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;500;600;700&display=swap);
:root {
  --clr-primary-200: #02a3ec;
  --clr-primary: #0295d8;
  --clr-primary-500: #028cca;
  --clr-primary-600: #027ab1;
}

body {
  font-family: "Source Sans Pro", sans-serif;
}
