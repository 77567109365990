:root {
  --primary-color: #008cd9;
  --white: #fff;
}

html {
  font-size: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
img {
  width: 100%;
  height: auto;
}
a {
  text-decoration: none;
  color: inherit;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
}
li {
  list-style: inside;
}
body {
  font-family: "Source Sans Pro", sans-serif;
  position: relative;
}
html,
body,
#root {
  height: 100%;
}
.wrapper {
  width: 90%;
  margin: 0 auto;
}
@media (min-width: 769px) {
  .wrapper {
    width: 80%;
  }
}
@media (min-width: 1440px) {
  .wrapper {
    width: 70%;
  }
}
.navigation {
  position: relative;
  background: #005ca9;
  color: #fff;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #00b3ff;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: 769px) {
    & {
      padding: 1rem 8rem;
    }
  }
}
.brand {
  align-content: center;
  &__logo img {
    width: 50px;
    height: auto;
  }
}

nav .nav__links a {
  letter-spacing: -0.5px;
  transition: all 150ms ease-in-out;
}
nav .nav__links a:hover {
  color: #00b3ff;
}
@media screen and (min-width: 769px) {
  nav {
    flex: 1 0 70%;
  }
  nav .nav__links a:not(:last-child) {
    margin-right: 1.5rem;
  }
}
.showNav {
  position: absolute;
  display: block;
  width: 100vw;
  top: 4.2rem;
  left: 0;
  text-align: right;
  z-index: 1;
  margin-right: -1rem;
  background-color: rgba(20, 20, 20, 0.8);
}
.showNav .nav__links {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.showNav .nav__links a {
  padding: 0.8rem;
}
.showNav .nav__links a:hover {
  background-color: #00b3ff;
  color: #000;
}
.hamburger div {
  width: 1.5rem;
  height: 0.15rem;
  background: #fff;
  border-radius: 1rem;
}
.hamburger div:not(:last-child) {
  margin-bottom: 0.3rem;
}
@media screen and (min-width: 769px) {
  .hamburger {
    display: none;
  }
}

footer {
  background: #2c2c2c;
  padding: 3rem 1.5rem;
  font-size: 0.8rem;
  line-height: 150%;
}
.copyright {
  margin: 2rem 0 0 0;
  font-size: 0.6rem;
}
@media screen and (min-width: 799px) {
  footer {
    padding: 5rem 0;
    font-size: 1rem;
    line-height: unset;
  }
  .copyright {
    margin: 3rem 0 -2rem 0;
    font-size: 0.8rem;
  }
}
.modal {
  position: absolute;
  display: flex;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
  top: -1000%;
  z-index: 1;
  position: fixed;
  justify-content: center;
  align-items: center;
  transition: all 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.slideDown {
  top: 0;
}

main {
  flex-grow: 1;
  position: relative;
}
footer div {
  margin: 0.5rem 0;
}
footer header {
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 1.5rem;
}
.social-media-link,
.social-media-link--black {
  width: 3rem;
  height: 3rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 2px solid #fff;
  border-radius: 50%;
  margin: 0.4rem 0.6rem;
  font-size: 1.2rem;
  color: #2c2c2c;
  transition: all 200ms ease-in-out;
}
.social-media-link--black {
  border-color: #000;
}
.social-media-link:hover,
.social-media-link--black:hover {
  background: #000;
  color: #fff;
}
.section {
  padding: 1rem 2rem;
  text-align: center;
  line-height: 30px;
  font-size: 0.9rem;
}
@media screen and (min-width: 769px) {
  .section {
    font-size: 1.3rem;
    padding: 3rem 12rem;
  }
}
.section__header {
  font-weight: 600;
  font-size: 2rem;
  margin: 2rem 0;
}
.section--moderate {
  padding: 3rem 3rem;
}
.section--black {
  background: #000;
  color: #fdf001;
}
.section--black header {
  color: #fff;
}
.section--blue {
  background: #005ca9;
  color: #fff;
}
.multi-column-section {
  display: flex;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .multi-column-section .centered,
  .multi-column-section .col-m70,
  .multi-column-section .col-m60,
  .multi-column-section .col-m40,
  .multi-column-section .col-m30 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .multi-column-section .col-m30 {
    flex: 30%;
  }
  .multi-column-section .col-m40 {
    flex: 40%;
  }
  .multi-column-section .col-m60 {
    flex: 60%;
  }
  .multi-column-section .col-m70 {
    flex: 70%;
  }
}
blockquote {
  border-left: 3px solid #cdf;
  margin: 1rem 0;
  padding-left: 1rem;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.georgia-font {
  font-family: Georgia, "Times New Roman", Times, serif;
}

.tag-house {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-size: 3rem;
  min-height: 300px;
  @media screen and (min-width: 769px) {
    & {
      min-height: 500px;
    }
  }
}
@media screen and (min-width: 769px) {
  section header {
    font-size: 2rem;
  }
}
.icon-container,
.icon-container--white {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0.2rem solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.icon-container--white {
  background: #fff;
}
.gray-text {
  color: #555;
}

.grid-m2 {
  margin: 2rem 0;
  display: grid;
  grid-column-gap: 4%;
  justify-content: center;
  width: 100%;
}
@media screen and (min-width: 769px) {
  .grid-m2 {
    grid-template-columns: repeat(2, 40%);
  }
}

/* Utility */
@media (min-width: 769px) {
  .text-md-margin-inline {
    max-width: 50ch;
  }
}
.meeting > div {
  width: 10rem;
  height: 10rem;
  border: 3px solid #fdf001;
}
.slide-container {
  position: relative;
}
.slide-container .prev,
.slide-container .next {
  position: absolute;
  top: 45%;
  left: 2%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: rgba(50, 50, 50, 0.5);
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: none;
  color: #fff;
  font-size: 0.8rem;
  cursor: pointer;
}
.slide-container .prev:hover,
.slide-container .next:hover {
  background: #fff;
  color: #000;
}
.slide-container .prev:disabled,
.slide-container .next:disabled {
  cursor: not-allowed;
}
.slide-container .next {
  left: unset;
  right: 2%;
}
@media screen and (min-width: 769px) {
  .slide-container .prev,
  .slide-container .next {
    width: 3.5rem;
    height: 3.5rem;
    font-size: 1.2rem;
  }
}
.slide img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: all ease-in-out 2s;
}
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}
@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
