.loading {
  --color: #008CD9;
  min-height: 40vh;
  span {
    width: 1rem;
    aspect-ratio: 1/1;
    background-color: var(--color);
    margin: 0 0.2rem;
    border-radius: 50%;
    animation: grow 600ms ease-in-out alternate infinite;
    &:nth-child(2) {
      animation-delay: 150ms;
    }
    &:nth-child(3) {
      animation-delay: 300ms;
    }
  }
  @keyframes grow {
    0% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1.1);
    }
  }
}
